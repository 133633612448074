import AppRoutes from "./Components/Routes/AppRoutes";
import Navbar from "./Components/Header&Footer/Navbar";
import useIsMobile from "./Components/Hook/useIsMobile";
import Hamburger from "./Components/Header&Footer/Hamburger";
import Footer from "./Components/Header&Footer/Footer";

function App() {
  const isMobile = useIsMobile();
  return (
    <div className="min-h-screen bg-light no-scrollbar relative">
      {isMobile ?
        <Hamburger />
        : <Navbar />}
      <div>
        <AppRoutes />
      </div>
      <Footer />
    </div>
  );
}

export default App;
