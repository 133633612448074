import { useNavigate } from 'react-router-dom';
import aboutUsPhoto from '../Assets/aboutus.jpg';
import hero from '../Assets/hero.jpg';

export default function AboutUs() {
    const navigate = useNavigate();

    return (
        <section className="min-h-screen">
            <div className="bg-cover bg-no-repeat bg-[50%] relative h-[400px] md:h-[700px]" style={{ backgroundImage: `url(${aboutUsPhoto})` }}>
                <div className="absolute inset-x-0 top-0 h-[400px] md:h-[700px] bg-gradient-to-b from-black z-10"></div>
                <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed z-10">
                    <div className="flex h-full items-center justify-center">
                        <div className="px-6 text-center text-white md:px-12">
                            <h1 className="mb-16 lg:text-5xl md:text-3xl text-xl font-bold tracking-tight xl:text-7xl">
                                Premier Construction Services in
                                <br />
                                <span>Auckland, New Zealand</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <main className="container mx-auto py-12 px-4 md:px-0">
                <section className="text-lg leading-relaxed">
                    <div className="max-w-5xl mx-auto">
                        <p>
                            Welcome to
                            <span className="font-bold"> Shining Star Builders</span>
                            , your premier choice for exceptional construction services based in {" "}
                            <strong>
                                Auckland, New Zealand.
                            </strong>
                            {" "}As certified builders, we bring unparalleled expertise, dedication, and craftsmanship to every project.
                        </p>
                        <p className="mt-4">At Shining Star Builders, we pride ourselves on delivering top-tier services at remarkably reasonable prices. Whether you need a renovation, fencing, or new construction, we're here to turn your dreams into reality.</p>
                        <p className="mt-4">With years of experience and a skilled team, we've established ourselves as leaders in the industry. From concept to completion, we work tirelessly to exceed your expectations.</p>
                        <p className="mt-4">At Shining Star Builders, transparency, integrity, and open communication are paramount. We're proud members of the NZ construction community, committed to excellence and ethics.</p>
                        <p className="mt-4">When you choose Shining Star Builders, you're choosing a partner invested in your success and satisfaction. Contact us today to discover the Shining Star difference.</p>
                    </div>
                </section>
                <div className="max-w-5xl mx-auto mt-8">
                    <button type='button' onClick={()=>navigate("/contact-us")} className="font-semibold bg-[#005656] px-6 py-2 rounded-lg text-white hover:animate-bounce">Contact us</button>
                    <div className="mt-12">
                        <h2 className="text-lg font-extrabold">Follow us</h2>
                        <ul className="flex mt-3 space-x-4">
                            <li className="bg-[#98AFC7] h-10 w-10 rounded-full flex items-center justify-center shrink-0 hover:animate-spin">
                                <a href="https://www.facebook.com/profile.php?id=61554673033341&mibextid=opq0tG">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20px"
                                        height="20px"
                                        fill="#005656"
                                        viewBox="0 0 24 24">
                                        <path d="M6.812 13.937H9.33v9.312c0 .414.335.75.75.75l4.007.001a.75.75 0 0 0 .75-.75v-9.312h2.387a.75.75 0 0 0 .744-.657l.498-4a.75.75 0 0 0-.744-.843h-2.885c.113-2.471-.435-3.202 1.172-3.202 1.088-.13 2.804.421 2.804-.75V.909a.75.75 0 0 0-.648-.743A26.926 26.926 0 0 0 15.071 0c-7.01 0-5.567 7.772-5.74 8.437H6.812a.75.75 0 0 0-.75.75v4c0 .414.336.75.75.75zm.75-3.999h2.518a.75.75 0 0 0 .75-.75V6.037c0-2.883 1.545-4.536 4.24-4.536.878 0 1.686.043 2.242.087v2.149c-.402.205-3.976-.884-3.976 2.697v2.755c0 .414.336.75.75.75h2.786l-.312 2.5h-2.474a.75.75 0 0 0-.75.75V22.5h-2.505v-9.312a.75.75 0 0 0-.75-.75H7.562z" data-original="#000000" />
                                    </svg>
                                </a>
                            </li>

                            <li className="bg-[#98AFC7] h-10 w-10 rounded-full flex items-center justify-center shrink-0 hover:animate-spin">
                                <a href="https://www.instagram.com/shiningstar_homes?igsh=MTJ1YTZpNDE3dXJtdw%3D%3D&utm_source=qr">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20px"
                                        height="20px"
                                        fill="#005656"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M12 9.3a2.7 2.7 0 1 0 0 5.4 2.7 2.7 0 0 0 0-5.4Zm0-1.8a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9Zm5.85-.225a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM12 4.8c-2.227 0-2.59.006-3.626.052-.706.034-1.18.128-1.618.299a2.59 2.59 0 0 0-.972.633 2.601 2.601 0 0 0-.634.972c-.17.44-.265.913-.298 1.618C4.805 9.367 4.8 9.714 4.8 12c0 2.227.006 2.59.052 3.626.034.705.128 1.18.298 1.617.153.392.333.674.632.972.303.303.585.484.972.633.445.172.918.267 1.62.3.993.047 1.34.052 3.626.052 2.227 0 2.59-.006 3.626-.052.704-.034 1.178-.128 1.617-.298.39-.152.674-.333.972-.632.304-.303.485-.585.634-.972.171-.444.266-.918.299-1.62.047-.993.052-1.34.052-3.626 0-2.227-.006-2.59-.052-3.626-.034-.704-.128-1.18-.299-1.618a2.619 2.619 0 0 0-.633-.972 2.595 2.595 0 0 0-.972-.634c-.44-.17-.914-.265-1.618-.298-.993-.047-1.34-.052-3.626-.052ZM12 3c2.445 0 2.75.009 3.71.054.958.045 1.61.195 2.185.419A4.388 4.388 0 0 1 19.49 4.51c.457.45.812.994 1.038 1.595.222.573.373 1.227.418 2.185.042.96.054 1.265.054 3.71 0 2.445-.009 2.75-.054 3.71-.045.958-.196 1.61-.419 2.185a4.395 4.395 0 0 1-1.037 1.595 4.44 4.44 0 0 1-1.595 1.038c-.573.222-1.227.373-2.185.418-.96.042-1.265.054-3.71.054-2.445 0-2.75-.009-3.71-.054-.958-.045-1.61-.196-2.185-.419A4.402 4.402 0 0 1 4.51 19.49a4.414 4.414 0 0 1-1.037-1.595c-.224-.573-.374-1.227-.419-2.185C3.012 14.75 3 14.445 3 12c0-2.445.009-2.75.054-3.71s.195-1.61.419-2.185A4.392 4.392 0 0 1 4.51 4.51c.45-.458.994-.812 1.595-1.037.574-.224 1.226-.374 2.185-.419C9.25 3.012 9.555 3 12 3Z"></path>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </main>
        </section>
    )
}