import renovationsPhoto from '../Assets/shining start builders (2).png';
import fencingPhoto from '../Assets/shining start builders (3).png';
import newBuildPhoto from '../Assets/shining start builders (4).png';
import interiorDesignPhoto from '../Assets/shining start builders (5).png';
import landscapingPhoto from '../Assets/shining start builders (6).png';
import roofingPhoto from '../Assets/shining start builders (7).png';
import extensionsPhoto from '../Assets/extensions.png';
import deckingPhoto from '../Assets/decking.png';
import retainingWallPhoto from '../Assets/retainingwall.png';
import insulationPhoto from '../Assets/insulation.png';
import foundation from '../Assets/foundation.png';
import framing from '../Assets/framing.png';


const servicesData = [
    {
        title: 'Renovations',
        image: renovationsPhoto,
        description: 'Our renovation services are tailored to breathe new life into your spaces, ensuring they align perfectly with your vision and needs.',
        number: 2
    },
    {
        title: 'Fencing',
        image: fencingPhoto,
        description: 'Secure your property with our top-notch fencing services, combining functionality with aesthetic appeal.',
        number: 3
    },
    {
        title: 'New Build',
        image: newBuildPhoto,
        description: 'From groundbreaking to completion, trust us for all your building needs, delivering excellence every step of the way.',
        number: 1
    },
    {
        title: 'Interior Design',
        image: interiorDesignPhoto,
        description: 'Transform your space with our expert interior design services, blending style and functionality seamlessly.',
        number: 11
    },
    {
        title: 'Landscaping',
        image: landscapingPhoto,
        description: 'Create breathtaking outdoor spaces with our professional landscaping services, tailored to your preferences and environment.',
        number: 9
    },
    {
        title: 'Roofing',
        image: roofingPhoto,
        description: 'Protect and enhance your property with our reliable roofing solutions, delivering durability and style.',
        number: 12
    },
    {
        title: 'Extensions',
        image: extensionsPhoto,
        description: 'Expand your living space with our extension services. Whether it\'s a larger kitchen, a brighter living area, or a versatile home office, our team is dedicated to bringing your vision to life.',
        number: 4

    },
    {
        title: 'Decking',
        image: deckingPhoto,
        description: 'Transform your outdoor space with our decking services. Whether it\'s a cozy entertainment area, a tranquil reading nook, or a vibrant outdoor dining space, our team crafts the perfect deck tailored to your lifestyle.',
        number: 9
    },
    {
        title: 'Retaining Walls',
        image: retainingWallPhoto,
        description: 'Strengthen your landscape with our durable retaining walls. Designed to prevent soil erosion and provide structural support, our walls blend functionality with aesthetic appeal, creating beautiful and resilient outdoor spaces tailored to your needs.',
        number: 8

    },
    {
        title: 'Insulation',
        image: insulationPhoto,
        description: 'Ensure year-round comfort and energy efficiency with our insulation services. Our team delivers top-tier insulation solutions, keeping your home warm in winter and cool in summer, while reducing energy costs and environmental impact.',
        number: 7

    },
    {
        title: 'Foundation',
        image: foundation,
        description: 'Build your dream home on a solid foundation. Our team delivers reliable foundation solutions, ensuring the structural integrity and longevity of your property.',
        number: 5
    },
    {
        title: 'Framing',
        image: framing,
        description: 'Our framing services, accredited by NZQFC, are designed to provide structural support and stability to your property, ensuring the longevity and durability of your home.',
        number: 6
    }
];


export default function Services() {
    return (
        <section className="lg:mt-16 mt-4">
            <h1 className="text-4xl text-center font-semibold">Services</h1>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 mx-auto gap-4 max-w-6xl mt-12 px-4 lg:px-0">
                {servicesData && servicesData.sort((a, b) => a.number - b.number).map((service, index) => (
                    <div key={index} className="flex flex-col items-start space-y-4">
                        <img className="rounded-lg w-full shadow-lg object-cover h-64" src={service.image} alt={service.title} />
                        <h3 className="text-xl font-semibold">{service.title}</h3>
                        <p className="text-neutral-600">{service.description}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}