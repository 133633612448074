import { BadgeCheck, Banknote, FileBadge2, Medal, ShieldCheck } from "lucide-react";

export default function WhyChooseUs() {
    return (
        <section className="bg-transparent py-6  md:px-0 rounded-lg">
            <h2 className="text-4xl font-semibold my-4 text-center">Why Choose Us</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-2 mx-auto gap-4 max-w-6xl mt-12 px-4 lg:px-0">
                <div className="bg-white p-6 rounded-md shadow-md">
                    <div className="flex space-x-2 mb-4 items-center">
                        <Medal color="#005656" size={28} />
                        <h3 className="text-xl font-semibold">
                            NZ Qualified Builders
                        </h3>
                    </div>
                    <p className="text-lg">Our team comprises highly skilled and certified builders with extensive experience in the industry.</p>
                </div>
                <div className="bg-white p-6 rounded-md shadow-md">
                    <div className="flex space-x-2 mb-4 items-center">
                        <BadgeCheck color="#005656" size={28} />
                        <h3 className="text-xl font-semibold">
                            Licensed Building Practitioners
                        </h3>
                    </div>
                    <p className="text-lg">We are proud members of the NZCB (New Zealand Certified Builders) and Licensed Building Practitioners showcasing our commitment to industry standards and best practices.</p>
                </div>
                <div className="bg-white p-6 rounded-md shadow-md">
                    <div className="flex space-x-2 mb-4 items-center">
                        <Banknote color="#005656" size={28} />
                        <h3 className="text-xl font-semibold">
                            Transparent Pricing
                        </h3>
                    </div>
                    <p className="text-lg">Enjoy top-tier construction services without compromising your budget. Our transparent pricing ensures you get the best value for your investment.</p>
                </div>
                <div className="bg-white p-6 rounded-md shadow-md">
                    <div className="flex space-x-2 mb-4 items-center">
                        <FileBadge2 color="#005656" size={28}/>
                        <h3 className="text-xl font-semibold">
                            Quality Assurance
                        </h3>
                    </div>
                    <p className="text-lg">From renovations to new constructions, we deliver superior craftsmanship and meticulous attention to detail, ensuring the longevity and durability of our projects.</p>
                </div>
            </div>
        </section>
    )
}