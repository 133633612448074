import { NavLink } from 'react-router-dom';
import hero from '../Assets/hero.jpg';
import Services from './Services';
import WhyChooseUs from './WhyChooseUs';

export default function Home() {
    return (
        <>
            <div className="bg-cover bg-no-repeat bg-[50%] relative" style={{ backgroundImage: `url(${hero})`, height: '700px' }}>
                <div className="absolute inset-x-0 top-0 h-[700px] bg-gradient-to-b from-black z-10"></div>
                <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed z-10">
                    <div className="flex h-full items-center justify-center">
                        <div className="px-6 text-center text-white md:px-12">
                            <h1 className="mb-16 lg:text-5xl md:text-3xl text-xl font-bold tracking-tight xl:text-7xl">
                                Empowering Your Construction Vision
                                <br />
                                <span>with Unmatched Quality and Service</span>
                            </h1>
                            <NavLink
                                to={'/contact-us'}
                                className="mb-2 inline-block rounded-md border-2 hover:border-primary border-white px-[46px] pt-[14px] pb-[12px] text-sm font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-white hover:bg-opacity-50 hover:text-primary focus:border-white focus:text-white focus:outline-none focus:ring-0 active:border-white active:text-white md:mr-2 md:mb-0"

                                role="button"
                            >Get in touch now!</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:-mb-12">
                <WhyChooseUs />
            </div>
            <Services />

        </>
    )
}