import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function EmailSent() {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col items-center justify-center h-[50vh] w-full">
            <div className="flex flex-col items-center justify-center mx-auto bg-white p-6 rounded-md shadow-lg">
                <svg viewBox="0 0 24 24" className="text-dark w-36 h-36 mb-6">
                    <path fill="currentColor"
                        d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
                    </path>
                </svg>
                <h1 className="text-3xl text-center font-semibold">Email Sent Successfully</h1>
                <p className="text-gray-500 mt-3 text-center">We have received your email and will get back to you soon.</p>
                <button onClick={() => navigate('/')} type="button" className="text-white bg-dark hover:animate-pulse px-8 py-2 rounded-md font-semibold z-50 mt-6">Continue</button>
            </div>
        </div>

    );
}
